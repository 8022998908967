<template lang="pug">
el-card.admin-home
  home-menu(:items='homeMenuItems', shrink)

  .information
    h2 最近のお知らせ
    information-list(:displayNum='5', :is-admin='role === "admin"')
</template>

<script>
import { mapGetters } from 'vuex'

import HomeMenu from '@/components/home-menu'
import InformationList from '@/components/information-list'

import getHomeMenuItems from '@/helpers/get-home-menu-items'

export default {
  components: {
    HomeMenu,
    InformationList,
  },

  computed: {
    ...mapGetters({
      role: 'app/role',
    }),
    homeMenuItems() {
      return getHomeMenuItems(this.role)
    },
  },
}
</script>

<style lang="sass" scoped>
.admin-home
  padding-bottom: 3rem

.information
  margin-top: 2rem
  h2
    font-weight: normal
</style>
